import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'annotationForm',
    'propertyForm'
  ];

  static values = {
    nextevent: String,
  }

  connect() {
    this.transitionType = $('#transition-type-select')[0]

    if (this.transitionType) {
        this.attachChangeEventListener();
    }

    if (this.element.querySelector('#link_to_state_transition')) {
      this.attachOpenModalListener();
    } else {
      this.attachClickEventListener();
    }
  }

  attachChangeEventListener() {
    this.transitionType.addEventListener('change', () => {
      const value = $('#transition-type-select').val();
      if (value === 'new_registration') {
        this.showPropertyForm();
      } else if (value === 'annotation') {
        this.showAnnotationForm();
      }
      this.hideTransitionTypeSelect();
    });
  }

  attachOpenModalListener() {
    this.element.addEventListener('show.bs.modal', (event) => {
      event.preventDefault();
      this.element.querySelector('#link_to_state_transition').click();
    });
  }

  attachClickEventListener() {
    this.element.addEventListener('click', (event) => {
      const forms = this.element.querySelectorAll('form');
      if (event.target.id === 'property-transition') {
        if (this.nexteventValue == 'subdivide') {
          const transitionType = this.transitionType.value;
          if (transitionType === 'new_registration') {
            this.submitPropertyForm();
          } else if (transitionType === 'annotation') {
            const lastForm = forms[forms.length - 1];
            lastForm.submit();
          }
        } else {
          const lastForm = forms[forms.length - 1];
          lastForm.submit();
        }
      }
    });
  }

  showPropertyForm() {
    this.propertyFormTarget.style.display = '';
    this.annotationFormTarget.style.display = 'none';
  }

  showAnnotationForm() {
    this.propertyFormTarget.style.display = 'none';
    this.annotationFormTarget.style.display = '';
  }

  hideTransitionTypeSelect() {
    this.transitionType.style.display = 'none';
  }

  submitPropertyForm() {
    const form = this.propertyFormTarget.querySelector('form');

    if (this.nextevent) {
      form.insertAdjacentHTML('beforeend', `<input type="hidden" name="event" value="${this.nextevent}">`);
    }
    form.submit();
  }
}
