// This file is auto_generated by ./bin/rails stimulus:manifest:update
// Run that command whenever you add a new controller or create them with
// ./bin/rails generate stimulus controllerName

import { application } from "./application"

import CameraController from "./camera_controller"
application.register("camera", CameraController)

import BudgetController from "./budget_controller"
application.register("budget", BudgetController)

import ChartController from "./chart_controller"
application.register("chart", ChartController)

import CompanyController from "./company_controller"
application.register("company", CompanyController)

import ContractorController from "./contractor_controller"
application.register("contractor", ContractorController)

import CorporateCardController from "./corporate_card_controller"
application.register("corporate-card", CorporateCardController)

import FixedAssetController from "./fixed_asset_controller"
application.register("fixed-asset", FixedAssetController)

import GeolocationController from "./geolocation_controller"
application.register("geolocation", GeolocationController)

import HazardReportController from "./hazard_report_controller"
application.register("hazard-report", HazardReportController)

import HelloController from "./hello_controller"
application.register("hello", HelloController)

import InvestmentsController from "./investments_controller"
application.register("investments", InvestmentsController)

import MapsController from "./maps_controller"
application.register("maps", MapsController)

import NetworkController from "./network_controller"
application.register("network", NetworkController)

import NotificationController from "./notification_controller"
application.register("notification", NotificationController)

import OrganigramController from "./organigram_controller"
application.register("organigram", OrganigramController)

import ParticipationsController from "./participations_controller"
application.register("participations", ParticipationsController)

import PaymentController from "./payment_controller"
application.register("payment", PaymentController)

import PropertyController from "./property_controller"
application.register("property", PropertyController)

import PropertiesController from "./properties_controller"
application.register("properties", PropertiesController)

import PunchesController from "./punches_controller"
application.register("punches", PunchesController)

import PunchController from "./punch_controller"
application.register("punch", PunchController)

import PurchaseRequestController from "./purchase_request_controller"
application.register("purchase-request", PurchaseRequestController)

import ReceiveMaterialsController from "./receive_materials_controller"
application.register("receive-materials", ReceiveMaterialsController)

import SiteDiaryController from "./site_diary_controller"
application.register("site-diary", SiteDiaryController)

import SupplierController from "./supplier_controller"
application.register("supplier", SupplierController)

import UsersController from "./users_controller"
application.register("users", UsersController)

import TransitionModalController from "./transition_modal";
application.register("transition-modal", TransitionModalController);

import QuoteController from "./quote_controller"
application.register("quote", QuoteController)

import EnterpriseController from "./enterprise_controller"
application.register("enterprise", EnterpriseController)

import SiteDiaryTaskController from "./site_diary_task_controller"
application.register("site-diary-task", SiteDiaryTaskController)

import QuoteRequestController from "./quote_request_controller"
application.register("quote-request", QuoteRequestController)

import MentionController from "./mention_controller"
application.register("mention", MentionController)

import ResourceController from "./resource_controller"
application.register("resource", ResourceController)
