import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets =
    ["notification"]

  connect() {
  };

  disconnect() {
    const ids = this.notificationTargets.map(element => element.dataset.id)
    _.each(ids, (id) => {
      this.markAsRead(id);
    })
  }

  markAsRead(id) {
    fetch(`/notifications/${id}/mark_as_read`, {
      method: "PATCH",
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    })
    .catch(error => {
      console.error("Error marking notifications as read:", error);
    });
  }

}
