import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';

export default class extends Controller {
  static targets = ['freightField', 'freightSelect', 'priceInput', 'quoteCard', 'quantity', 'totalPrice', 'discount', 'freightPrice', 'submit'];

  connect() {
    this.bindPriceInput();

    this.quoteCardTargets.forEach((card, index) => {
      const priceInputTarget = card.querySelector('[data-quote-request-target="priceInput"]');
      priceInputTarget.addEventListener('input', () => this.updateTotalPrice());

      const quantityInputTarget = card.querySelector('#quoteQuantity');
      quantityInputTarget.addEventListener('input', () => this.updateTotalPrice());
    });

    this.freightFieldTarget.addEventListener('input', () => this.updateTotalPrice());
    this.discountTarget.addEventListener('input', () => this.updateTotalPrice());
  };


  bindPriceInput() {
    this.priceInputTargets.forEach((input) => {
      $(input).on('input', () => {
        let inputValue = input.value.replace(/[^\d]/g, '');
        if (inputValue.length === 0) {
          input.value = '';
          return;
        }

        // Format the input value
        const numericValue = parseFloat(inputValue) / 100;
        const formattedValue = this.formatValue(numericValue);

        // Update the input value
        input.value = formattedValue;
      });

      $(input).closest('form').on('submit', () => {
        let value = input.value;
        value = value.replace('R$ ', '').replace('.', '').replace(',', '.');
        $(input).val(value);
      });
    });
  }

  toggleFreightField() {
    if (this.freightSelectTarget.value === 'true') {
      this.freightFieldTarget.style.display = 'block';
      return;
    }
    this.freightFieldTarget.style.display = 'none';
    this.freightFieldTarget.querySelector('input').value = '0';
    this.updateTotalPrice();
  }

  updateTotalPrice() {
    let totalResourcePrice = 0;

    this.quoteCardTargets.forEach((card, index) => {
      const quantityInputTarget = card.querySelector('#quoteQuantity').value;
      const quantity = parseFloat(quantityInputTarget) || 0;

      const priceInputTarget = card.querySelector('[data-quote-request-target="priceInput"]');
      const price = parseFloat(priceInputTarget.value.replace(/[^\d]/g, '')) || 0;
      const formatedPrice = (price/100).toFixed(2);

      const total = formatedPrice * quantity;

      totalResourcePrice += total;
    });

    const freightPrice = this.freightFieldTarget.querySelector('input').value.replace(/[^\d]/g, '') || 0;
    const formatedFreightPrice = (freightPrice/100).toFixed(2);

    const discount = this.discountTarget.value.replace(/[^\d]/g, '') || 0;
    const formatedDiscount = (discount/100).toFixed(2);

    const totalPrice = totalResourcePrice + parseFloat(formatedFreightPrice) - parseFloat(formatedDiscount);

	  this.checkSubmitButton(totalPrice);

    this.totalPriceTarget.value = this.formatValue(totalPrice);
  }

  checkSubmitButton(totalPrice){
    if (totalPrice > 0){
	  this.submitTarget.disabled = false;
	  return
    }
	this.submitTarget.disabled = true;
  }

  formatValue(value){
    return 'R$ ' + value.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });

  }
}
